<template>
  <div class="transport-screen">
    <slot name="map"/>

    <div class="transport-screen__control">
      <slot name="control"/>
    </div>
  </div>
</template>

<script>
export default {
name: "TransportWrapper"
}
</script>

<style lang="scss">
.transport-screen {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 1143px;
  overflow-x: hidden;
  overflow-y: auto;

  &__control {
    position: relative;
    z-index: 401;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    background-color: #1e1e1e;
  }
}
</style>
