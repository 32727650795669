<template>
  <div class="transport-card">

    <button
        v-show="objectShow"
        class="transport-card__button object"
        @click="toggleBtn('objects')"
    >
      <img class="transport-card__button-img" src="@/assets/img/house.svg">
      Объекты
    </button>

    <button
        v-show="!objectShow"
        class="transport-card__button"
        @click="toggleBtn('transport')"
    >
      <img class="transport-card__button-img" src="@/assets/img/transport.svg">
      Транспорт
    </button>

    <div v-if="transport" class="top-box">
      <div class="transport-card__search">
        <img class="transport-card__search-img" src="@/assets/img/search.svg"/>
        <input
            @focus="focused = true"
            class="transport-card__search-input"
            type="text"
            v-model="value"
            placeholder="Название остановки"
        />
        <img
            src="@/assets/img/close.svg"
            class="transport-card__search-img--close"
            @click="closeKeyboard"
        />
      </div>

      <div
          v-if="value.length > 1"
          class="swiper-slider">
        <swiper
            :options="{
            freeMode: true,
            slidesPerView: 'auto',
            touchRatio: 2,
          }"
        >
          <swiper-slide
              v-for="(item, index) in dataAutocomplete"
              :key="index"
              @click.native="goObject(item)"
          >
            {{ item.title }}
          </swiper-slide>
        </swiper>
      </div>

      <keyboard
          :focused="focused"
          :value="value"
          :is-keyboard="isKeyboard"
          @change-value="changeValue"
          @add-space="addSpace"
          @delete-symbol="deleteSymbol"
          @show-keyboard="showKeyboard"
      />

      <div
          @click="goToTimetable"
          v-if="!focused"
          class="transport-card__routes-table">
        <p class="transport-card__routes-table-title">Маршруты и расписание</p>
        <img class="transport-card__routes-table-img" src="@/assets/img/bus.png">
      </div>
    </div>

    <div
        v-if="objects"
        class="objects">
      <div class="tags">
        <swiper
            :options="{
            slidesPerView: 'auto',
            touchRatio: 2
          }"
        >
          <swiper-slide
              class="slide-tag"
          >
            <tag
                title="Все"
                :active="activeBadge === 0"
                @click.native="changeBadge(0)"
            />
          </swiper-slide>
          <swiper-slide
              v-for="(tag, index) in cities"
              :key="index"
              class="slide-tag"
          >
            <tag
                :title="tag.title"
                :active="tag.id === activeBadge"
                @click.native="changeBadge(tag.id)"
            />
          </swiper-slide>
        </swiper>
      </div>

      <div class="object-cards">
        <swiper
            :options="{
            slidesPerView: 'auto',
            touchRatio: 2
          }"
        >
          <swiper-slide
              class="object-cards__card"
              v-for="event in events"
              :key="event.id"
              @click.native="goToEvent(event)"
          >
            <events-mini-card
                :width="219"
                :event="event"
            />
          </swiper-slide>
        </swiper>
      </div>

    </div>
  </div>
</template>

<script>
import Keyboard from "@/components/Parts/Keyboard";
import Tag from "@/components/Parts/Tag";
import EventsMiniCard from "@/components/Parts/EventsMiniCard";

export default {
  name: "TransportCard",
  components: {
    Keyboard,
    Tag,
    EventsMiniCard
  },
  data() {
    return {
      value: "",
      focused: false,
      activeBadge: 0,
      cities: [],
      events: [],
      objectShow: true,
      transport: true,
      objects: false,
      btnObjects: true,
      btnTransport: false,
      dataAutocomplete: [],
      isKeyboard: false,
      stations: [],
    }
  },
  mounted() {
    this.getAllEvents();

    api.getBadges().then(res => {
      this.badges = res.data;
    });

    api.getCity().then(res => {
      this.cities = res.data.sort((a, b) => a.id - b.id)
    });

    api.getStations().then(res => {
      this.stations = res;
    });
  },

  watch: {
    value() {
      if (this.value) {
        let results = [];
        const terms = this.autocompleteMatch(this.value, this.stations);

        for (let i = 0; i < terms.length; i++) {
          results.push(terms[i]);
        }

        this.dataAutocomplete = [...results];
      } else {
        return;
      }
    }
  },

  methods: {
    autocompleteMatch(input, data) {
      if (input == '') {
        return [];
      }

      const reg = new RegExp(input, 'i');

      return data.filter(term => {
        if (term.title.match(reg)) {
          return term;
        }
      });
    },

    goToTimetable: function() {
      this.$router.push('/transport/routes-timetable');
    },

    changeValue(value) {
      this.value += value;
    },

    addSpace(space) {
      this.value += space;
    },

    deleteSymbol(symbol) {
      this.value = symbol;
    },

    closeKeyboard() {
      this.focused = false;
      this.value = "";
    },

    goToEvent(event) {
      this.$router.push({path: `/event/${event.id}`});
      this.$store.dispatch("setSelectedEvent", event);
    },

    changeBadge(id) {
      this.activeBadge = id;
    },

    getAllEvents() {
      api.getAllEvents().then(res => {
        this.events = res.data;
      });
    },

    toggleBtn(value) {
      this.objectShow = !this.objectShow;

      if (value === "objects") {
        this.transport = false;
        this.objects = true;
        this.$emit("toggle-map", true);
      }

      if (value === "transport") {
        this.objects = false;
        this.transport = true;
        this.$emit("toggle-map", false);
      }
    },

    goObject(item) {
      this.$emit("go-bus-station", item.id);
      this.isKeyboard = false;
    },

    showKeyboard(isShow) {
      this.isKeyboard = isShow;
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-slider {
  width: calc(100% - 32px);
  margin-bottom: 32px;
  margin-left: 32px;
}

.swiper-container {
  margin: 0;
}

.swiper-slide {
  width: auto;
  max-width: 440px;
  padding: 24px 20px;
  margin-left: 24px;
  font-size: 30px;
  line-height: 32px;
  background-color: #3a3a3c;
  color: #fff;
  border-radius: 14px;

  &:nth-of-type(1) {
    margin-left: 0;
  }

  &:nth-last-child(1) {
    margin-right: 32px;
  }

  &.slide-tag {
    padding: 0;
    margin-right: 0;

    &:nth-last-child(1) {
      margin-right: 32px;
    }
  }
}

.objects {
  width: calc(100% - 32px);
  margin-left: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.tags {
  width: 100%;
}

.object-cards {
  margin-top: 32px;

  &__card {
    padding: 0;
  }
}

.top-box {
  // посмотреть почему swiper растягивает весь контент если выставить width 100%
  width: 1080px;
}

.transport-card {
  width: 100%;

  .link {
    text-decoration: none;
  }

  &__button {
    position: absolute;
    top: -142px;
    right: 32px;
    z-index: 401;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 333px;
    height: 100px;
    font-size: 36px;
    font-weight: 500;
    line-height: 64px;
    color: #0b83ff;
    background-color: rgba(#fff, 0.9);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05);
    border: none;
    border-radius: 14px;
    outline: none;

    &-img {
      width: 78px;
      height: 78px;
      padding: 19px;
      margin-right: 15px;
      background-color: #0b83ff;
      border-radius: 55px;
    }

    &.object {
      color: #089e41;

      img {
        background-color: #38cf71;
      }
    }
  }

  &__search {
    position: relative;
    padding: 32px 32px 32px 32px;

    &-img {
      position: absolute;
      top: 50%;
      transform: translate(24px, -50%);

      &--close {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-100%, -25px);
      }
    }

    &-input {
      width: 100%;
      background: transparent;
      border: 1px solid rgba(#fff, 0.7);
      border-radius: 14px;
      padding: 25px;
      padding-left: 86px;
      font-size: 36px;
      font-weight: 400;
      line-height: 64px;
      color: rgba(#fff, 0.7);
    }
  }

  &__routes-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 32px 32px 32px;
    background: linear-gradient(261.01deg, #2EEFAD -6.89%, #32A7E0 74.69%);
    border-radius: 14px;

    &-title {
      padding: 40px 26px;
      font-size: 44px;
      font-weight: 500;
      line-height: 48px;
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
