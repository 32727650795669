<template>
  <div class="routes">
    <swiper
      class="swiper direct-routes"
      :options="{
        slidesPerView: 'auto',
        touchRatio: 2,
      }"
    >
      <swiper-slide
        v-for="item in properties.scheduleStationRoutes"
        :key="item.route.id"
      >
        <div
          class="route"
          :class="{'route--active': item.route.id === activeBadge}"
          @click="clickRoute(item)"
        >
          <img class="route__img" src="@/assets/img/busIcon.svg">
          <p class="route__name-route">
            {{ item.route.title }}
          </p>
        </div>
      </swiper-slide>
    </swiper>

    <div class="selected-route" v-if="!showSchedule">
      <div class="selected-route__item">
        <img class="route__img" src="@/assets/img/busIcon.svg">

        <div class="selected-route__info">
          <div class="selected-route__number">
            {{ currentNumber }}
          </div>
          <div class="selected-route__route">
            {{ firstLastStation }}
          </div>
        </div>
      </div>

      <div class="selected-route__btn" @click="changeToSchedule" v-if="currentScheduleSet">
        Расписание
      </div>
    </div>

    <routes-line
      v-if="!showSchedule"
      :routes="CURRENT_BUS_ROUTE_GEOMETRY.stations"
      :stop="properties.getStop"
      :active-route="currentNumber"
      @switch-bus-station="switchBus"
    />

    <div v-show="showSchedule">
      <div class="schedule-items" v-if="!error.status && schedule.length > 0">
        <div class="schedule-items__item"
          v-for="(item, index) in schedule"
          :key="index"
          :class="timeFormat(item).isPassed ? '' : 'schedule-items__item--active'"
        >
          {{ timeFormat(item).time }}
        </div>
      </div>

      <div class="schedule-items" v-else>
        <div class="schedule-items__error">
          Нет доступного расписания
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

import RoutesLine from "@/components/Parts/RoutesLine";

export default {
  components: {
    RoutesLine
  },

  props: {
    properties: {
      type: Object,
      default: () => ({})
    },
    routes: {
      type: Array,
      default: () => ([])
    }
  },

  async mounted() {
    await this.GET_ROUTE_SCHEDULE_FROM_SERVER({
      params: {
        routeId: this.currentRouteId,
        stopId: this.currentStopId
      }
    });
    await this.GET_BUS_ROUTE_GEOMETRY(this?.properties?.scheduleStationRoutes[0]?.route?.id);

    let activeBadge = this?.properties?.scheduleStationRoutes[0]?.route?.id;
    this.activeBadge = activeBadge;
  },

  computed: {
    ...mapGetters(["GET_ROUTE_SCHEDULE", "CURRENT_BUS_ROUTE_GEOMETRY",]),

    currentScheduleSet: function() {
      let currentSet = [];

      if (this.GET_ROUTE_SCHEDULE?.routes?.length > 0) {
        currentSet = this.GET_ROUTE_SCHEDULE?.routes[0]?.stations?.filter(item => item.station.id == this.currentStopId);
      }

      console.log(currentSet, 'currentSet')

      if (currentSet[0]?.arrivals?.length > 0) {
        return currentSet[0].arrivals;
      }

      return [];
    },

    firstLastStation() {
      const first = this.CURRENT_BUS_ROUTE_GEOMETRY?.stations[0]?.station?.title || '';
      const last = this.CURRENT_BUS_ROUTE_GEOMETRY?.stations[this.CURRENT_BUS_ROUTE_GEOMETRY.stations.length - 1]?.station?.title || '';
      return `${first} — ${last}`;
    },
  },
  data() {
    return {
      currentNumber: this?.properties?.scheduleStationRoutes[0]?.route?.title,

      currentRouteId: this?.properties?.scheduleStationRoutes[0]?.route?.id,
      currentStopId: this?.properties?.getStop?.id,

      showSchedule: false,
      error: {
        status: false,
        message: ''
      },

      schedule: ['11:44', '12:44'],
      activeBadge: 0
    }
  },
  methods: {
    ...mapActions(["GET_BUS_ROUTE_GEOMETRY", "GET_ROUTE_SCHEDULE_FROM_SERVER"]),

    timeFormat: function(time) {
      const currentTime = time.replace('.', ':');
      const currentTimeMoment = this.$moment(currentTime, 'HH:mm');

      const currentTimeZoneTime = this.$moment(this.$moment().utcOffset(process.env.VUE_APP_CURRENT_TIMEZONE).format('HH:mm'), 'HH:mm');

      return {
        time: this.$moment(currentTimeMoment).format('HH:mm'),
        isPassed: currentTimeMoment.isBefore(currentTimeZoneTime),
      }
    },

    async clickRoute(item) {
      this.schedule = [];
      this.error.status = false;
      this.currentNumber = item.route.title;
      this.currentRouteId = item.route.id;
      this.activeBadge = item.route.id;

      await this.GET_BUS_ROUTE_GEOMETRY(item.route.id);
      await this.GET_ROUTE_SCHEDULE_FROM_SERVER({
        params: {
          routeId: item.route.id,
          stopId: this.properties.getStop.id,
        }
      }).then(() => {
        this.setActiveTime();
      }).catch(error => {
        this.error.status = true;
        this.error.message = error.response.data.message;
      });

      this.$emit('get-direct', item);
    },

    changeToSchedule() {
      this.showSchedule = !this.showSchedule;
      this.setActiveTime();
    },

    setActiveTime: function() {
      const node = document.getElementsByClassName('schedule-items__item--active')[0];

      if (node !== undefined && node !== null) {
          node.setAttribute('id', 'firstActive');
          location.href = '#firstActive';
      }
    },

    switchBus(busStation) {
      this.$emit("switch-bus-station", busStation);
    }
  },

  watch: {
    currentScheduleSet: function(newVal) {
      this.schedule = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  width: auto;
}

.swiper-container {
  margin-left: 0;
}

.swiper {
  width: 1048px;
}

.routes {
  width: calc(100% - 32px);
  margin: 32px;
}

.direct-routes {
  width: 100%;
}

.route {
  display: flex;
  align-items: center;
  width: auto;
  padding: 16px;
  margin-right: 32px;
  background: #3A3A3C;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  opacity: 0.9;

  &__img {
    padding: 12px;
    margin-right: 16px;
    background: #0B83FF;
    border-radius: 100px;
    border: 2px solid transparent;
  }

  &__name-route {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 35px;
    letter-spacing: -0.5px;
    color: #fff;
  }

  &--active {
    background-color: #1a8bff;

    img {
      border: 2px solid #fff;
    }
  }
}

.selected-route {
  width: 1016px;

  display: flex;
  align-content: center;
  justify-content: space-between;

  margin-top: 32px;
  margin-bottom: 32px;

  &__item {
    display: flex;

    img {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      margin-right: 24px;
    }
  }

  &__number {
    display: flex;
    align-content: center;
    flex-shrink: 0;
    font-weight: normal;
    font-size: 54px;
    line-height: 55px;
    letter-spacing: -1.8px;
    color: #fff;
  }

  &__route {
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #fff;
    opacity: 0.7;
    margin-top: 4px;
  }

  &__btn {
    height: 66px;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    color: #fff;
    background: linear-gradient(180deg, rgba(75, 75, 75, 0.35) 0.65%, rgba(36, 36, 35, 0.65) 100%), #4B4B4B;
    border-radius: 56px;
    padding: 12px 40px;
  }

  &__routes-line {
    padding: 37px 0 0 0;
  }
}

.schedule-items {
  height: 272px;
  width: 1016px;

  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 26px;

  &__error {
    width: 100%;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: rgba(255, 255, 255, 0.97);
    text-align: center;
  }

  &__item {
    height: 80px;
    width: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: rgba(255, 255, 255, 0.97);

    opacity: .5;

    background: #3A3A3C;
    border-radius: 14px;
    padding: 16px;
    margin-right: 16px;
    margin-bottom: 16px;

    &--selected {
      background: #1A8BFF;
    }

    &--active {
      opacity: 1;
    }
  }
}
</style>
